<template>
  <div v-if="notification.length > 0">
    <b-alert
      v-for="notif in notification"
      :key="notif.id"
      :show="notif.show"
      dismissible
      class="p-1"
      :style="`background-color: ${notif.color} !important`"
      @dismissed="dismissNotificationBar(notif)"
    >
      <span
        class="text-[#222222] text-[20px] font-bold"
      >{{ notif.title }}</span><br>
      <div
        class="text-[#222222] text-[16px] font-normal"
        style="max-width: 90%;"
      >
        {{ notif.description }}&nbsp;
        <a
          class="text-[#222222] hover:text-[#222222] font-semibold"
          :href="notif.url_link"
          target="_blank"
        >{{ notif.url_link }}</a>
      </div>

      <template slot="dismiss">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/close-circle.svg"
          style="cursor:pointer"
        >
      </template>
    </b-alert>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notification: [],
    }
  },
  mounted() {
    if (localStorage.getItem('notifSession')) {
      try {
        this.notification = JSON.parse(localStorage.getItem('notifSession'))
      } catch (e) {
        localStorage.removeItem('notifSession')
        this.getNotificationBar()
      }
    } else this.getNotificationBar()
  },
  methods: {
    getNotificationBar() {
      this.$http_komship
        .get('/v1/partner/notification-bar', {
          params: {
            type: 'partner',
          },
        })
        .then(result => {
          const { data } = result.data
          this.notification = data.map(items => ({
            id: items.id,
            title: items.title,
            description: items.description,
            url_link: items.url_link,
            color: items.color,
            show: true,
          }))
        })
        .catch(err => console.error(err))
    },
    dismissNotificationBar(value) {
      const index = this.notification.findIndex(items => items.id === value.id)
      this.notification[index].show = false
      const parsed = JSON.stringify(this.notification)
      localStorage.setItem('notifSession', parsed)
    },
  },
}
</script>
